<template>
	<router-view />
</template>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		.el-dropdown-link {
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		.tableContent {
			height: 100%;

			.btnList {
				height: 50px;
				line-height: 50px;
			}

			.el-table {
				height: calc(100% - 100px);
			}

			.el-pagination {
				justify-content: center;
				height: 50px;
				padding: 0;
			}
		}

		.upload-image-box .el-upload {
			width: 100%;
		}

		.ql-editor {
			height: 300px;
		}

		.swiper-wrapper {
			transition-timing-function: linear !important;
		}
	}
</style>
