import {
	createRouter,
	createWebHashHistory
} from "vue-router";

const routes = [{
	path: '/',
	redirect: '/home',
	component: () => import('@/views/website'),
	children: [{
		path: '/home', // 首页
		name: 'home',
		component: () => import('@/views/website/home')
	}, {
		path: '/detail', // 详情
		name: 'detail',
		component: () => import('@/views/website/detail')
	}, {
		path: '/companyIntro', // 公司介绍
		name: 'companyIntro',
		component: () => import('@/views/website/companyIntro')
	}, {
		path: '/resourceDownload', // 详情
		name: 'resourceDownload',
		component: () => import('@/views/website/resourceDownload')
	}]
}, {
	path: '/login', // 登录
	name: 'login',
	component: () => import('@/views/system/login')
}, {
	path: '/system',
	redirect: '/banner',
	component: () => import('@/views/system'),
	children: [{
		path: '/banner', // 轮播图
		name: 'banner',
		component: () => import('@/views/system/banner')
	}, {
		path: '/introduce', // 功能介绍
		name: 'introduce',
		component: () => import('@/views/system/introduce')
	}, {
		path: '/function', // 功能模块
		name: 'function',
		component: () => import('@/views/system/function')
	}, {
		path: '/news', // 新闻
		name: 'news',
		component: () => import('@/views/system/news')
	}, {
		path: '/resources', // 资源管理
		name: 'resources',
		component: () => import('@/views/system/resources')
	}, {
		path: '/company', // 公司介绍
		name: 'company',
		component: () => import('@/views/system/company')
	}, {
		path: '/enterprise', // 企业信息
		name: 'enterprise',
		component: () => import('@/views/system/enterprise')
	}]
}];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
