import {
	createApp
} from 'vue'
import App from './App.vue'
const app = createApp(App)

// 引入路由
import router from './router'
app.use(router)

// 引入vuex
import store from './store'
app.use(store)

// 引入element-plus
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
app.use(ElementPlus, {
	locale: zhCn,
})

// 引入element-plus图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

// 引入element-plus样式
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'

// 引入主题样式
import '@/common/common.css'

// proxy转json
import {
	toRaw
} from '@vue/reactivity'
app.config.globalProperties.$toRaw = toRaw;

// 引入动画样式
import animated from 'animate.css'
app.use(animated)

app.mount('#app')
